import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { SmartLink } from './SmartLink';
import { theme } from '../domain/theme';

export const SponsoredTag = ({ text, bgColor, url }) => {
  if (typeof text !== 'undefined' && text !== '') {
    const styles = {
      sponsoredTag: css`
        width: fit-content;
        padding: 4px 8px 5px 8px;
        background-color: ${bgColor};
        color: ${bgColor === 'black' ? 'white' : 'black'};
        a {
          color: ${bgColor === 'black' ? 'white' : 'black'};
        }
        font-size: 12px;
        font-weight: 600;
        ${theme.font.family('boldWeb')};
        line-height: 15px;
        letter-spacing: 3px;
        text-transform: uppercase;
      `,
      colors: bgColor
        ? css`
            background-color: ${bgColor};
            color: ${bgColor === 'black' ? 'white' : 'black'};
            a {
              color: ${bgColor === 'black' ? 'white' : 'black'};
            }
          `
        : null
    };

    return (
      <div css={[styles.sponsoredTag, styles.colors]}>
        {url ? (
          <SmartLink to={url} rel="sponsored">
            {text}
          </SmartLink>
        ) : (
          text
        )}
      </div>
    );
  }
  return null;
};

SponsoredTag.defaultProps = {
  text: undefined,
  bgColor: undefined,
  url: undefined
};

SponsoredTag.propTypes = {
  text: PropTypes.string,
  bgColor: PropTypes.string,
  url: PropTypes.string
};
